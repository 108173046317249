import './App.css';

function App() {
  return (
    <div className="App">
			<section id="banner">
				<div class="inner">
					<h1>Hanna Grygarowicz<br /> <span>
          Gabinet Psychoterapii Psychodynamicznej
					</span></h1>
				</div>
			</section>

			<section id="one">
				<div class="inner">
					<header>
						<h2>O mnie - oferta terapii</h2>
					</header>
					<p>Jestem psychologiem i psychoterapeutką psychodynamiczną. Prowadzę prywatną praktykę psychoterapeutyczną na warszawskim Muranowie. W gabinecie przyjmuję młodzież od 16 r.ż. i dorosłych doświadczających trudności w relacjach prywatnych oraz zawodowych, odczuwających lęk, napięcie, trwałe obniżenie nastroju i poszukujących pomocy w zmianie swojego dotychczasowego funkcjonowania. Oferuję pomoc osobom z objawami zaburzeń lękowych,  zaburzeń nastroju, zaburzeń psychosomatycznych, zaburzeń odżywiania, zaburzeń osobowości, a także w sytuacjach kryzysu emocjonalnego czy rozwojowego np. w przebiegu nagłych zmian w życiu.</p>
					<p>Ukończyłam Wydział Psychologii Uniwersytetu Warszawskiego oraz czteroletnie szkolenie psychoterapeutyczne w Krakowskim Centrum Psychodynamicznym. Należę do Polskiego Towarzystwa Psychoterapii Psychodynamicznej (PTPPd) i na bieżąco konsultuję prowadzone procesy terapeutyczne u polecanych przez PTPPd superwizorów.</p>					
				</div>
			</section>

			<section id="two">
				<div class="inner">
					<article>
						<div class="content">
							<header>
								<h3>Doświadczenie</h3>
							</header>
							<p>Poza praktyką prywatną pracuję także jako psycholog w Zespole Oddziałów Neurologii Mazowieckiego Szpitala Bródnowskiego, gdzie zajmuję się pacjentami z depresją, zaburzeniami lękowymi, psychosomatycznymi, osobami w kryzysie, a także prowadzę  diagnozę i terapię neuropsychologiczną. </p>
							<p>Wcześniejsze doświadczenie zawodowe zbierałam jako psychoterapeuta w Poradni Zdrowia Psychicznego oraz w Poradni Psychologicznej Kochowskiego na warszawskim  Żoliborzu. Uczestniczyłam także w stażach klinicznych m.in w Instytucie Psychiatrii i Neurologii, na Klinicznym Oddziale Psychiatrycznym Szpitala Wolskiego, na Oddziale Dziennym Psychiatrycznym Szpitala Bielańskiego.</p>
						</div>
					</article>
					<article class="alt">
						<div class="content">
							<header>
								<h3>Jak rozpocząć</h3>
							</header>
							<p>
							Terapię poprzedzają konsultacje kwalifikujące (średnio około 3 spotkań), podczas których  dowiaduję się o powodach zgłoszenia i historii pacjenta, wspólnie ustalamy  cele terapii oraz omawiamy jej zasady. Jest to także czas w którym pacjent decyduje się na podjęcie terapii w proponowanym nurcie.</p>
							<p>Zapraszam!</p>
							
							<h3>Cennik</h3>
							<p>Sesja psychoterapii – 50 min – 200 zł <br />
							Konsultacja kwalifikująca na terapię – 50 min – 200 zł
							</p>
						</div>
					</article>
				</div>
			</section>

		


			<section id="footer">
				<div class="inner">
					<header>
						<h2>Kontakt</h2>
					</header>

					
						<div class="field half first">
              <label for="name">+48 514 778 719</label>
              <label for="name">hannagrygarowicz@gmail.com</label>
			  <label for="name">ul. Nowolipki 10, lok. 62</label>
			  <label for="name">Warszawa</label>
						</div>
				</div>
			</section>

			<script src="assets/js/jquery.min.js"></script>
			<script src="assets/js/skel.min.js"></script>
			<script src="assets/js/util.js"></script>
			<script src="assets/js/main.js"></script>

    </div>
  );
}

export default App;
